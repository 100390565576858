<template>
  <div class="animated fadeIn">
    <b-card>
      <InvoiceHeader v-bind:invoice="invoice" v-bind:vwref="vwref" v-bind:serviceData="serviceData" v-bind:afes="afes"
        v-bind:vid="true" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name">
      </InvoiceHeader>
      <div class="mt-2 invoice-status">
        <div v-if="invoice.accountant_paid === 0">
          <div v-if="invoice.inv_passed === 9">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
          </div>
          <div v-if="invoice.inv_passed === 17">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Accountant</button>
          </div>
          <div v-if="invoice.inv_passed === 8 && invoice.acc_project != 1">
            <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
          </div>

          <div v-if="invoice.inv_passed === 8 && invoice.acc_project == 1">
            <button class="btn-sm btn-warning btn-pm"
              style="background: #24cabf !important; border-color: #24cabf !important"><i class="fa fa-question"></i>
              Pending For First Approval</button>
          </div>

          <div v-if="invoice.inv_passed === 7">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 6">
            <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher
              Management</button>
          </div>

          <div v-if="invoice.inv_passed === 5">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Project Manager (Pending
              Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 0">
            <button class="btn-sm btn-warning"><i class="fa fa-question"></i> Pending By WSS</button>
          </div>

          <div v-if="invoice.inv_passed === 2">
            <button class="w-100 text-center d-block btn-sm btn-blue"><i class="fa fa-save"></i> Draft</button>
          </div>

          <div v-if="invoice.inv_passed === 11">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 13">
            <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher
              Management</button>
          </div>

          <div v-if="invoice.inv_passed === 14">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending
              Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 21">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 23">
            <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher
              Management</button>
          </div>

          <div v-if="invoice.inv_passed === 24">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending
              Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 31">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 33">
            <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher
              Management</button>
          </div>

          <div v-if="invoice.inv_passed === 34">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending
              Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 41">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 43">
            <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher
              Management</button>
          </div>

          <div v-if="invoice.inv_passed === 44">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending
              Vendor)</button>
          </div>

          <div v-if="invoice.inv_passed === 51">
            <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
          </div>

          <div v-if="invoice.inv_passed === 54">
            <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending
              Vendor)</button>
          </div>
        </div>
        <div v-if="invoice.accountant_paid === 1">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
        </div>
      </div>
      <b-row class="mt-3 clearfix mb-4">
        <b-col>
          <VendorDetails v-bind:invoice="invoice" v-bind:vid="true"></VendorDetails>
        </b-col>
        <b-col>
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="company_name"></CompanyDetails>
        </b-col>
      </b-row>
      <b-row class="mt-3 clearfix mb-4">
        <InvoiceDetails v-bind:invoice="invoice" v-bind:toggleEditVIN="true" v-bind:userData="userData"
          v-bind:vwref="vwref" v-on:removeAll="removeAll()" v-bind:uwiData="uwiData" v-bind:serviceData="serviceData"
          v-bind:afes="afes" v-bind:toggleVselect="true"></InvoiceDetails>
      </b-row>
      <InvoiceAfes v-bind:afes="afes" v-bind:serviceData="serviceData" v-bind:invoice="invoice"
        v-bind:allowSubmit="false"></InvoiceAfes>
      <b-row>
        <b-col lg="12" class="mt-4 col-12 ml-auto">
          <InvoiceTotals v-bind:invoice="invoice" v-bind:serviceData="serviceData" v-bind:currency="currency">
          </InvoiceTotals>
        </b-col>
      </b-row>
      <hr />
      <InvoiceHistory v-bind:history="invoice"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import { Validator } from 'vee-validate';
import moment from 'moment';
import VendorDetails from './../invoice/InvoiceVendorDetails'
import CompanyDetails from './../invoice/InvoiceCompanyDetails'
import InvoiceDetails from './../invoice/InvoiceDetails'
import InvoiceHeader from './../invoice/InvoiceHeader'
import InvoiceServices from './../invoice/InvoiceServices'
import InvoiceAfes from './../invoice/InvoiceAfes'
import InvoiceTotals from './../invoice/InvoiceTotals'
import InvoiceHistory from './../invoice/InvoiceHistory'

export default {
  name: 'VendorCreateInvoice',
  components: {
    vSelect,
    Datepicker,
    InvoiceHeader,
    VendorDetails,
    InvoiceServices,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
  },
  data() {
    return {
      uom: [
        { value: 'L', label: 'L' },
        { value: 'KG', label: 'KG' },
      ],
      vendorDetails: [],
      serviceNames: [
        { value: 'Drilling', label: 'Drilling', code: '9294' },
        { value: 'Water', label: 'Water', code: '9594' },
      ],
      allafe: [
        { value: '34353', label: '34353' },
        { value: '65434', label: '65434' },
      ],
      uwiData: [],
      service: {
        startdate: '',
        enddate: '',
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: "",
      fieldTicketNumber: '',
      vendorInvoiceNumber: '',
      serviceOrderNumber: '',
      programNumber: '',
      treatmentType: '',
      vendorInvoiceDate: new Date().toISOString().slice(0, 10),
      afesTemp: [],
      netDays: 0,
      percent: 0,
      afeData: [],
      gst: 5,
      history: [],
      vwref: [],
      quickpay: 0,
      services: [],
      serviceData: [],
      comments: "",
      invoiceError: false,
      tax_percentage: 0,
      company_name: '',
      invoice: [],
      currency: '',
      tax: '',
      servicenameSort: [],
      vid: 0,
      loading: false,
      userData: []
    }
  },
  methods: {
    removeAll() {
      this.loading = true;
      this.afes.forEach((v, i) => {
        let data = { id: v.apid, service_ccid: v.service_ccid, projectid: this.invoice.projectid }
        this.$axios.post('/wss/delete/service_ap', data)
          .then(response => {
            this.afes = [];
            if (i === this.afes.length - 1) {
              this.$toasted.show("Invoice coding is changed for all line items.", {
                type: "success",
                duration: "3000",
              });
            }
          })
          .catch(error => { })
      });
      this.loading = false;
    },
    addNewAfe: function (id, vsid) {
      this.vsid = vsid;
      this.addToInvoice = 0;
      this.addAfeButtons = 0;

      this.$http.get("/vendor/get/invoice_services_chain/" + this.invoice.projectid + "/" + this.invoice.invoiceid)
        .then(response => {
          this.serviceChainData = response.data.result;
          this.addAfeShow = 1;

          if (this.serviceChainData.length === 0) {
            this.addAfeShow = 0;
            this.addToInvoice = 0;
            this.addAfeButtons = 1;
            this.$toasted.show('No Cost Center / Well Numbers Found.', { type: 'error', duration: '3000' })
            this.error = true;
          }
        })
        .catch(error => {
        })
    },
    cancelAfe: function () {
      this.addToInvoice = 1;
      this.addAfeButtons = 1;
      this.addAfeShow = 0;
      this.serviceChainData = [];
    },
    addAfeToInvoice: function (afe) {
      let data = JSON.stringify({
        id: this.vsid,
        projectid: this.invoice.projectid,
        service_ccid: this.afe.service_ccid,
        percentage: this.percent,
      })
      this.$axios.post('/vendor/create/service_ap', data)
        .then(response => {
          this.afes.push({
            afenum: this.afe.afenum,
            percentage: this.percent,
            apid: response.data.apid,
            cc_num: this.afe.cc_num,
            ccone_code: this.afe.ccone_code,
            cctwo_code: this.afe.cctwo_code,
            ccthree_code: this.afe.ccthree_code,
          })
          this.addAfeShow = 0;
          this.afe = [];
          this.percent = 0;
          this.addAfeButtons = 1;
        })
        .catch(error => {
          this.$toasted.show('Error. Duplicate Cost Center / Well.', { type: 'error', duration: '3000' })
        })
    },
    removeAfe: function (index, apid) {
      Vue.delete(this.afes, index);
      let data = JSON.stringify({
        id: apid,
        projectid: this.invoice.projectid,
      })
      this.$axios.post('/vendor/delete/service_ap', data)
        .then(response => {
          this.$toasted.show('Cost Center / Well Removed', { type: 'success', duration: '3000' })
        })
        .catch(error => {
        })
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    submitInvoice(status) {
      if (status == 1 && this.totalAfe !== 100) {
        this.$toasted.show('Cost Center / Well total not 100%.', { type: 'error', duration: '3000' })
        return;
      }
      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        vendor_stat: status,
        vendor_note: this.comments,
        tax_percentage: this.invoice.tax_percentage
      })
      this.$http.post('/vendor/edit/invoice', data)
        .then(response => {
          this.$toasted.show('Invoice Edited Successfully', { type: 'success', duration: '3000' });
          this.$router.push('/vendor')
        })
        .catch(error => {
          this.invoiceError = true;
        })
    },
    loadData() {
      var a = moment(this.invoice.endtday);
      var b = moment(this.invoice.creation_date);
      this.netDays = a.diff(b, 'days')

      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;
      var cid = this.invoice.companyid;

      this.$http.get('/vendor/get/invoice/full_services/' + cid + "/" + pid + '/' + invid)
        .then(response => {
          this.afesTemp = response.data.result;
        })
        .catch(error => {
          this.$toasted.show('Error.', { type: 'error', duration: '3000' })
        })
      return
    },
    getServicesInProject() {
      return this.$axios.get('/vendor/get/allowed_services/' + this.invoice.companyid + '/' + this.invoice.projectid)
        .then(response => {
          console.log(response)
          return response.data.result;
        }).catch(error => {
          console.log(error)
        })
    },
    async loadUwis() {
      this.getServicesInProject();
      this.$axios.get("/vendor/get/service_chains/" + this.invoice.companyid + "/" + this.invoice.vid)
        .then(response => {
          this.uwiData = response.data.result;
          this.uwiData.forEach(val => val.uwi_4 = `${val.uwi !== null ? val.uwi : ''}-${val.uwi_2 !== null ? val.uwi_2 : ''}-${val.uwi_3 !== null ? val.uwi_3 : ''}-${val.pwid}`)
          this.vwref = this.uwiData.filter(x => x.uwi === this.invoice.vwref && x.projectid === this.invoice.projectid)[0];
        })
        .catch(() => { });
    },
  },
  computed: {
    highestAfe: function () {
      if (this.afes.length > 0) {
        var res = Math.max.apply(Math, this.afes.map(function (o) { return o.percentage; }))
        return this.afes.find(function (o) { return o.percentage == res; })
      }
    },
    totalAfe() {
      return this.afes.reduce(function (total, item) {
        var total = total + parseInt(item.percentage);
        return total;
      }, 0);
    },
    subtotal: function () {

      return this.serviceData.reduce(function (total, item) {
        return total + (item.quantity * item.pu) - ((item.discount / 100) * item.quantity * item.pu);
      }, 0);
    },
    afes: function () {
      const that = this;
      return this.afesTemp.map(function (afe) {
        afe.index = that.serviceData.findIndex(x => x.vsid === afe.vsid)
        return afe
      });
    },
  },
  mounted() {

    if (!this.$route.params.invoice) {
      this.invoice = this.$session.get('invoice');
    }
    else {
      this.invoice = this.$route.params.invoice;
    }
    var pid = this.invoice.projectid;
    var invid = this.invoice.invoiceid;
    var cid = this.invoice.companyid;
    this.history = this.invoice;

    this.loadUwis();

    this.$http.get('/vendor/get/invoice/services/' + cid + "/" + pid + '/' + invid)
      .then(response => {
        this.serviceData = this.lodash.sortBy(response.data.result, 'name');
        this.currency = this.serviceData[0].currency;
      })
      .catch(error => {
        this.$toasted.show('Error.', { type: 'error', duration: '3000' })
      })
    this.loadData();

    this.$http.get('/vendor/get/allowed_companies/')
      .then(response => {
        this.companyData = response.data.result.filter(x => x.companyid === this.invoice.companyid);
        this.company_name = this.companyData[0].cn;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        this.userData = [{ cn: this.company_name, un: this.invoice.vfname + this.invoice.vlname, role: 'vendor', date: today, invoice: this.invoice }];

      })
      .catch(error => {

      })
  }
}
</script>
