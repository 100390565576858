var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c("InvoiceHeader", {
            attrs: {
              invoice: _vm.invoice,
              vwref: _vm.vwref,
              serviceData: _vm.serviceData,
              afes: _vm.afes,
              vid: true,
              currency: _vm.currency,
              tax: _vm.tax,
              company_name: _vm.company_name
            }
          }),
          _c("div", { staticClass: "mt-2 invoice-status" }, [
            _vm.invoice.accountant_paid === 0
              ? _c("div", [
                  _vm.invoice.inv_passed === 9
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By WSS (Submit Again)")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 17
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(" Rejected By Accountant")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 8 && _vm.invoice.acc_project != 1
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-pm" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Project Manager")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 8 && _vm.invoice.acc_project == 1
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn-sm btn-warning btn-pm",
                            staticStyle: {
                              background: "#24cabf !important",
                              "border-color": "#24cabf !important"
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v("\n            Pending For First Approval")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 7
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 6
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Higher\n            Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 5
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Project Manager (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 0
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-warning" }, [
                          _c("i", { staticClass: "fa fa-question" }),
                          _vm._v(" Pending By WSS")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 2
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-100 text-center d-block btn-sm btn-blue"
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v(" Draft")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 11
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 13
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Higher\n            Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 14
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Management (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 21
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 23
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Higher\n            Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 24
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Management (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 31
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 33
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Higher\n            Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 34
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Management (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 41
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 43
                    ? _c("div", [
                        _c(
                          "button",
                          { staticClass: "btn-sm btn-warning btn-management" },
                          [
                            _c("i", { staticClass: "fa fa-question" }),
                            _vm._v(" Pending By Higher\n            Management")
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 44
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Management (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 51
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-success" }, [
                          _c("i", { staticClass: "fa fa-check" }),
                          _vm._v(" AP Team Review")
                        ])
                      ])
                    : _vm._e(),
                  _vm.invoice.inv_passed === 54
                    ? _c("div", [
                        _c("button", { staticClass: "btn-sm btn-danger" }, [
                          _c("i", { staticClass: "fa fa-times" }),
                          _vm._v(
                            " Rejected By Management (Pending\n            Vendor)"
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.invoice.accountant_paid === 1
              ? _c("div", [
                  _c("button", { staticClass: "btn-sm btn-success" }, [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Paid")
                  ])
                ])
              : _vm._e()
          ]),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c(
                "b-col",
                [
                  _c("VendorDetails", {
                    attrs: { invoice: _vm.invoice, vid: true }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("CompanyDetails", {
                    attrs: {
                      invoice: _vm.invoice,
                      company_name: _vm.company_name
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3 clearfix mb-4" },
            [
              _c("InvoiceDetails", {
                attrs: {
                  invoice: _vm.invoice,
                  toggleEditVIN: true,
                  userData: _vm.userData,
                  vwref: _vm.vwref,
                  uwiData: _vm.uwiData,
                  serviceData: _vm.serviceData,
                  afes: _vm.afes,
                  toggleVselect: true
                },
                on: {
                  removeAll: function($event) {
                    return _vm.removeAll()
                  }
                }
              })
            ],
            1
          ),
          _c("InvoiceAfes", {
            attrs: {
              afes: _vm.afes,
              serviceData: _vm.serviceData,
              invoice: _vm.invoice,
              allowSubmit: false
            }
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "mt-4 col-12 ml-auto", attrs: { lg: "12" } },
                [
                  _c("InvoiceTotals", {
                    attrs: {
                      invoice: _vm.invoice,
                      serviceData: _vm.serviceData,
                      currency: _vm.currency
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("hr"),
          _c("InvoiceHistory", { attrs: { history: _vm.invoice } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }